export const list1 = [
  {
    type: 1,
    url: require("@/assets/images/xbzq/photo1-1.png"),
    newUrl: require("@/assets/images/xbzq/photo1-1-1.png"),
    id: 0,
    coordinate: "0,0",
    fragment: "photo1-1"
  },
  {
    type: 1,
    url: require("@/assets/images/xbzq/photo1-2.png"),
    newUrl: require("@/assets/images/xbzq/photo1-2-1.png"),
    id: 1,
    coordinate: "0,1",
    fragment: "photo1-2"
  },
  {
    type: 1,
    url: require("@/assets/images/xbzq/photo1-3.png"),
    newUrl: require("@/assets/images/xbzq/photo1-3-1.png"),
    id: 2,
    coordinate: "1,0",
    fragment: "photo1-3"
  },
  {
    type: 1,
    url: require("@/assets/images/xbzq/photo1-4.png"),
    newUrl: require("@/assets/images/xbzq/photo1-4-1.png"),
    id: 3,
    coordinate: "1,1",
    fragment: "photo1-4"
  }
];

export const list2 = [
  {
    type: 2,
    url: require("@/assets/images/xbzq/photo1-5.png"),
    newUrl: require("@/assets/images/xbzq/photo1-5-1.png"),
    id: 4,
    coordinate: "0,0",
    fragment: "photo2-1"
  },
  {
    type: 2,
    url: require("@/assets/images/xbzq/photo1-6.png"),
    newUrl: require("@/assets/images/xbzq/photo1-6-1.png"),
    id: 5,
    coordinate: "0,1",
    fragment: "photo2-2"
  },
  {
    type: 2,
    url: require("@/assets/images/xbzq/photo1-7.png"),
    newUrl: require("@/assets/images/xbzq/photo1-7-1.png"),
    id: 6,
    coordinate: "1,0",
    fragment: "photo2-3"
  },
  {
    type: 2,
    url: require("@/assets/images/xbzq/photo1-8.png"),
    newUrl: require("@/assets/images/xbzq/photo1-8-1.png"),
    id: 7,
    coordinate: "1,1",
    fragment: "photo2-4"
  },
  {
    type: 2,
    url: require("@/assets/images/xbzq/photo1-9.png"),
    newUrl: require("@/assets/images/xbzq/photo1-9-1.png"),
    id: 8,
    coordinate: "2,0",
    fragment: "photo2-5"
  }
];

export const list3 = [
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-10.png"),
    newUrl: require("@/assets/images/xbzq/photo1-10-1.png"),
    id: 9,
    coordinate: "0,0",
    fragment: "photo3-1"
  },
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-11.png"),
    newUrl: require("@/assets/images/xbzq/photo1-11-1.png"),
    id: 10,
    coordinate: "0,1",
    fragment: "photo3-2"
  },
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-12.png"),
    newUrl: require("@/assets/images/xbzq/photo1-12-1.png"),
    id: 11,
    coordinate: "1,0",
    fragment: "photo3-3"
  },
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-13.png"),
    newUrl: require("@/assets/images/xbzq/photo1-13-1.png"),
    id: 12,
    coordinate: "1,1",
    fragment: "photo3-4"
  },
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-14.png"),
    newUrl: require("@/assets/images/xbzq/photo1-14-1.png"),
    id: 13,
    coordinate: "2,0",
    fragment: "photo3-5"
  },
  {
    type: 3,
    url: require("@/assets/images/xbzq/photo1-15.png"),
    newUrl: require("@/assets/images/xbzq/photo1-15-1.png"),
    id: 14,
    coordinate: "2,1",
    fragment: "photo3-6"
  }
];

export const list4 = [
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-16.png"),
    newUrl: require("@/assets/images/xbzq/photo1-16-1.png"),
    id: 15,
    coordinate: "0,0",
    fragment: "photo4-1"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-17.png"),
    newUrl: require("@/assets/images/xbzq/photo1-17-1.png"),
    id: 16,
    coordinate: "0,1",
    fragment: "photo4-2"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-18.png"),
    newUrl: require("@/assets/images/xbzq/photo1-18-1.png"),
    id: 17,
    coordinate: "0,2",
    fragment: "photo4-3"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-19.png"),
    newUrl: require("@/assets/images/xbzq/photo1-19-1.png"),
    id: 18,
    coordinate: "1,0",
    fragment: "photo4-4"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-20.png"),
    newUrl: require("@/assets/images/xbzq/photo1-20-1.png"),
    id: 19,
    coordinate: "1,1",
    fragment: "photo4-5"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-21.png"),
    newUrl: require("@/assets/images/xbzq/photo1-21-1.png"),
    id: 20,
    coordinate: "1,2",
    fragment: "photo4-6"
  },
  {
    type: 4,
    url: require("@/assets/images/xbzq/photo1-22.png"),
    newUrl: require("@/assets/images/xbzq/photo1-22-1.png"),
    id: 21,
    coordinate: "2,0",
    fragment: "photo4-7"
  }
];

export const list5 = [
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-23.png"),
    newUrl: require("@/assets/images/xbzq/photo1-23-1.png"),
    id: 22,
    coordinate: "0,0",
    fragment: "photo5-1"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-24.png"),
    newUrl: require("@/assets/images/xbzq/photo1-24-1.png"),
    id: 23,
    coordinate: "0,1",
    fragment: "photo5-2"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-25.png"),
    newUrl: require("@/assets/images/xbzq/photo1-25-1.png"),
    id: 24,
    coordinate: "0,2",
    fragment: "photo5-3"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-26.png"),
    newUrl: require("@/assets/images/xbzq/photo1-26-1.png"),
    id: 25,
    coordinate: "1,0",
    fragment: "photo5-4"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-27.png"),
    newUrl: require("@/assets/images/xbzq/photo1-27-1.png"),
    id: 26,
    coordinate: "1,1",
    fragment: "photo5-5"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-28.png"),
    newUrl: require("@/assets/images/xbzq/photo1-28-1.png"),
    id: 27,
    coordinate: "1,2",
    fragment: "photo5-6"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-29.png"),
    newUrl: require("@/assets/images/xbzq/photo1-29-1.png"),
    id: 28,
    coordinate: "2,0",
    fragment: "photo5-7"
  },
  {
    type: 5,
    url: require("@/assets/images/xbzq/photo1-30.png"),
    newUrl: require("@/assets/images/xbzq/photo1-30-1.png"),
    id: 29,
    coordinate: "2,1",
    fragment: "photo5-8"
  }
];

export const list6 = [
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-31.png"),
    newUrl: require("@/assets/images/xbzq/photo1-31-1.png"),
    id: 30,
    coordinate: "0,0",
    fragment: "photo6-1"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-32.png"),
    newUrl: require("@/assets/images/xbzq/photo1-32-1.png"),
    id: 31,
    coordinate: "0,1",
    fragment: "photo6-2"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-33.png"),
    newUrl: require("@/assets/images/xbzq/photo1-33-1.png"),
    id: 32,
    coordinate: "0,2",
    fragment: "photo6-3"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-34.png"),
    newUrl: require("@/assets/images/xbzq/photo1-34-1.png"),
    id: 33,
    coordinate: "1,0",
    fragment: "photo6-4"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-35.png"),
    newUrl: require("@/assets/images/xbzq/photo1-35-1.png"),
    id: 34,
    coordinate: "1,1",
    fragment: "photo6-5"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-36.png"),
    newUrl: require("@/assets/images/xbzq/photo1-36-1.png"),
    id: 35,
    coordinate: "1,2",
    fragment: "photo6-6"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-37.png"),
    newUrl: require("@/assets/images/xbzq/photo1-37-1.png"),
    id: 36,
    coordinate: "2,0",
    fragment: "photo6-7"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-38.png"),
    newUrl: require("@/assets/images/xbzq/photo1-38-1.png"),
    id: 37,
    coordinate: "2,1",
    fragment: "photo6-8"
  },
  {
    type: 6,
    url: require("@/assets/images/xbzq/photo1-39.png"),
    newUrl: require("@/assets/images/xbzq/photo1-39-1.png"),
    id: 38,
    coordinate: "2,2",
    fragment: "photo6-9"
  }
];
